import React, { useEffect, useState } from "react";

export default function FormSelectButtonGeneric({
  label,
  allAttributes,
  availableAttributes,
  value,
  handleChange,
  tier,
}) {
  // clg(label);
  // clg({ availableAttributes });

  // not used
  // const disabled = false;

  const handleButtonClick = (e) => {
    e.preventDefault();
    handleChange(e);
  };

  if (tier === "mounting") {
    return (
      <>
        <div className="configurator-attribute-row">
          <h5 className="pb-xs">Category</h5>
          <ul className="button-capsule-link-row">
            {allAttributes.map((mounting, i) => (
              <li key={`fm-${i}`} value={mounting.recordId}>
                <button
                  type="button"
                  className={`button-capsule-link button-select button-select-filter-temp small ${
                    mounting.recordId === value ? "selected" : ""
                  }`}
                  onClick={handleButtonClick}
                  value={mounting.recordId}
                  data-label={label}
                  data-tier={tier}
                  data-selected={mounting.recordId === value}
                  disabled={
                    // !value &&
                    availableAttributes &&
                    !availableAttributes.find(
                      (available) => available.recordId === mounting.recordId
                    )
                  }
                >
                  {mounting.data.Name}
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* <select
          onChange={handleChange}
          value={value}
          data-label={label}
          data-tier={tier}
        >
          <option value="">Category</option>
          {allAttributes.map((mounting, i) => (
            <option
              key={`fm-${i}`}
              value={mounting.recordId}
              disabled={
                // !value &&
                availableAttributes &&
                !availableAttributes.find(
                  (available) => available.recordId === mounting.recordId
                )
              }
            >
              {mounting.data.Name}
            </option>
          ))}
        </select> */}
      </>
    );
  }

  if (label === "IP Rating") {
    // clg("IP Rating", value);
    // clg(availableAttributes.filter((a) => a >= 65));
    // clg(availableAttributes);
    return (
      <>
        <div className="configurator-attribute-row">
          <h5 className="pb-xs">IP Rating</h5>
          <ul className="button-capsule-link-row">
            <li>
              <button
                type="button"
                className={`button-capsule-link button-select button-select-filter-temp small ${
                  value === "44+" ? "selected" : ""
                }`}
                onClick={handleButtonClick}
                value="44+"
                data-label={label}
                data-tier={tier}
                data-selected={value === "44+"}
                disabled={
                  !availableAttributes.filter((a) => a <= 44).length > 0
                }
              >
                44+
              </button>
            </li>
            <li>
              <button
                type="button"
                className={`button-capsule-link button-select button-select-filter-temp small ${
                  value === "65+" ? "selected" : ""
                }`}
                onClick={handleButtonClick}
                value="65+"
                data-label={label}
                data-tier={tier}
                data-selected={value === "65+"}
                disabled={
                  !availableAttributes.filter((a) => a >= 65).length > 0
                }
              >
                65+
              </button>
            </li>
          </ul>
        </div>
        {/* <select
          onChange={handleChange}
          value={value}
          data-label={label}
          data-tier={tier}
        >
          <option value="">{label}</option>
          <option
            value="44+"
            disabled={!availableAttributes.filter((a) => a >= 44).length > 0}
          >
            44+
          </option>
          <option
            value="65+"
            disabled={!availableAttributes.filter((a) => a >= 65).length > 0}
          >
            65+
          </option>
        </select> */}
      </>
    );
  }

  // TODO Flux filter does not work properly
  if (label === "Luminous Flux") {
    // const fluxOptions = {
    //   "0 - 999lm": false,
    //   "1000lm - 1999lm": false,
    //   "2000lm+": false,
    // };
    // // clg(availableAttributes.filter((a) => a < 1000));
    // // clg(availableAttributes.filter((a) => a >= 2000));
    // if (Array.isArray(availableAttributes)) {
    //   if (availableAttributes.filter((a) => a < 1000)) {
    //     fluxOptions["0 - 999lm"] = true;
    //   }
    //   if (availableAttributes.filter((a) => a >= 1000 && a < 2000)) {
    //     fluxOptions["1000lm - 1999lm"] = true;
    //   }
    //   if (availableAttributes.filter((a) => a >= 2000)) {
    //     fluxOptions["2000lm+"] = true;
    //   }
    // }
    // // clg({ options });
    return (
      <>
        <select
          onChange={handleChange}
          value={value}
          data-label={label}
          data-tier={tier}
        >
          <option value="">{label}</option>
          <option
            value="0 - 999lm"
            disabled={!availableAttributes.filter((a) => a < 1000).length > 0}
          >
            0 - 999lm
          </option>
          <option
            value="1000lm - 1999lm"
            disabled={
              !availableAttributes.filter((a) => a >= 1000 && a < 2000).length >
              0
            }
          >
            1000lm - 1999lm
          </option>
          <option
            value="2000lm+"
            disabled={!availableAttributes.filter((a) => a >= 2000).length > 0}
          >
            2000lm+
          </option>
        </select>
      </>
    );
  }

  return (
    <>
      <div className="configurator-attribute-row">
        <h5 className="pb-xs">{label}</h5>
        <ul className="button-capsule-link-row">
          {allAttributes.map((option, i) => (
            <li key={`option-${i}`}>
              <button
                type="button"
                className={`button-capsule-link button-select button-select-filter-temp small ${
                  value === option ? "selected" : ""
                }`}
                onClick={handleButtonClick}
                value={option}
                data-label={label}
                data-tier={tier}
                data-selected={value === option}
                disabled={
                  Array.isArray(availableAttributes) &&
                  !availableAttributes.find((e) => e === option)
                }
              >
                {option}
              </button>
            </li>
          ))}
        </ul>
      </div>
      {/* <select
        onChange={handleChange}
        value={value}
        data-label={label}
        data-tier={tier}
      >
        <option value="">{label}</option>
        {allAttributes.map((option, i) => (
          <option
            key={`option-${i}`}
            value={option}
            disabled={
              // !value &&
              // disabled === true ||
              Array.isArray(availableAttributes) &&
              !availableAttributes.find((e) => e === option)
            }
          >
            {option}
          </option>
        ))}
      </select> */}
    </>
  );
}
