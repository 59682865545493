import {
  numericalSort,
  onlyUnique,
  sortControlProtocols,
  sortFinishes,
} from "./helpers";

function filterT1sByFinish({ T1s, finish }) {
  if (finish) {
    return T1s.data.webFinish === finish;
  }
  return true;
}

export const filterProductsSimply = ({ products, filters }) => {
  // clg({ products });
  // clg({ filters });
  const T1s = [...products.T1s];
  const T2s = [...products.T2s];
  const mountings = [...products.mountings];
  // clg({ filters });
  // Filter by T1s
  if (
    filters.T1 &&
    Object.entries(filters.T1).length > 0 &&
    Object.values(filters.T1).filter((item) => item).length > 0
  ) {
    Object.entries(filters.T1).forEach(([key, value]) => {
      if (value === "") return;
      const loopT1s = [];
      switch (key) {
        case "Finish":
          loopT1s.push(...T1s.filter((T1) => T1.data.webFinish === value));
          break;
        case "Colour Temperature":
          loopT1s.push(
            ...T1s.filter((T1) => T1.data.webColourTemperature === value)
          );
          break;
        case "Control Protocol":
          loopT1s.push(
            ...T1s.filter(
              (T1) =>
                T1.data.webControlProtocolIntegrated === value ||
                T1.data.webControlProtocolRemote === value
            )
          );
          break;
        case "Luminous Flux":
          // ["0 - 999lm", "1000lm - 1999lm", "2000lm+"]
          switch (value) {
            default:
            case "0 - 999lm":
              loopT1s.push(
                ...T1s.filter(
                  (T1) =>
                    Array.isArray(T1.data.webFlux) &&
                    T1.data.webFlux.length > 0 &&
                    T1.data.webFlux[0] < 1000
                )
              );
              break;
            case "1000lm - 1999lm":
              loopT1s.push(
                ...T1s.filter(
                  (T1) =>
                    Array.isArray(T1.data.webFlux) &&
                    T1.data.webFlux.length > 0 &&
                    T1.data.webFlux[0] < 2000 &&
                    T1.data.webFlux[0] >= 1000
                )
              );
              break;
            case "2000lm+":
              loopT1s.push(
                ...T1s.filter(
                  (T1) =>
                    Array.isArray(T1.data.webFlux) &&
                    T1.data.webFlux.length > 0 &&
                    T1.data.webFlux[0] >= 2000
                )
              );
              break;
          }
          break;
        case "Beam Angle":
          loopT1s.push(
            ...T1s.filter((T1) => T1.data.webBeamCategory?.includes(value))
          );
          break;
        case "Driver Location":
          switch (value) {
            case "Remote":
              loopT1s.push(
                ...T1s.filter((T1) => T1.data.webControlProtocolRemote !== null)
              );
              break;
            case "Integrated":
              loopT1s.push(
                ...T1s.filter(
                  (T1) => T1.data.webControlProtocolIntegrated !== null
                )
              );
              break;

            default:
              break;
          }
          break;
        default:
          // clg("Invalid filter", key, value);
          loopT1s.push(...T1s);
          break;
      }
      // Replace T1s with loopT1s
      // if (loopT1s.length > 0) T1s.splice(0, T1s.length, ...loopT1s);
      T1s.splice(0, T1s.length, ...loopT1s);
      // clg({ loopT1s });
    });
  }

  if (
    filters.T2 &&
    Object.entries(filters.T2).length > 0 &&
    Object.values(filters.T2).filter((item) => item).length > 0
  ) {
    Object.entries(filters.T2).forEach(([key, value]) => {
      const loopT1s = [];
      switch (key) {
        case "Power":
          loopT1s.push(
            ...T1s.filter(
              (T1) => T1.data.webPower && T1.data.webPower === value.toString()
            )
            // ...T1s.filter(
            //   (T1) =>
            //     T1.data.webPower &&
            //     Array.isArray(T1.data.webPower) &&
            //     T1.data.webPower.includes(value.toString())
            // )
          );
          break;
        case "IP Rating":
          switch (value) {
            case "44+":
              loopT1s.push(
                ...T1s.filter(
                  (T1) =>
                    T1.data.webIPRating && parseInt(T1.data.webIPRating) >= 44
                )
              );
              break;
            case "65+":
              loopT1s.push(
                ...T1s.filter(
                  (T1) =>
                    T1.data.webIPRating && parseInt(T1.data.webIPRating) >= 65
                )
              );
              break;
            default:
              break;
          }
          break;

        default:
          // clg("Invalid T2 filter", key, value);
          break;
      }
      T1s.splice(0, T1s.length, ...loopT1s);
    });
  }

  if (filters.mounting && filters.mounting.Category) {
    const poolT1s = [
      ...T1s.filter((T1) =>
        T1.data.webMountings?.includes(filters.mounting.Category)
      ),
    ];
    T1s.splice(0, T1s.length, ...poolT1s);
  }

  const T2sFromT1s = [];
  T1s.forEach((T1) => {
    T2sFromT1s.push(...T1.data.T2);
  });
  const T2sFromT1sUnique = T2sFromT1s.filter(onlyUnique);
  const T2sFromT1sRecords = [];
  if (T2sFromT1sUnique.length > 0) {
    T2sFromT1sUnique.forEach((recordId) => {
      const found = products.T2s.find((baseT2) => baseT2.recordId === recordId);
      if (found) {
        T2sFromT1sRecords.push(found);
      }
    });
  }
  T2s.splice(0, T2s.length, ...T2sFromT1sRecords);

  // Get Mountings from T2
  const mountingIds = [];
  T2s.forEach((T2) => {
    if (T2.data.webMounting) {
      mountingIds.push(...T2.data.webMounting);
    }
  });

  // Collate available mountings
  const availableMountings = [];
  mountingIds.filter(onlyUnique).forEach((id) => {
    const record = mountings.find(({ recordId }) => recordId === id);
    if (record) availableMountings.push(record);
  });

  return {
    T2s,
    T1s,
    mountings: availableMountings.sort((a, b) =>
      a.data.webDisplayOrder > b.data.webDisplayOrder ? 1 : -1
    ),
  };
};

export const filterProducts = ({ allProducts, activeFilters }) => {
  const T1s = [...allProducts.T1s];
  const T2s = [...allProducts.T2s];
  const mountings = [...allProducts.mountings];

  function filterMountingsBySurface(mounting) {
    // if (attributeMountingSurface) {
    //   return mounting.data.webMountingSurface === attributeMountingSurface;
    // }
    return true;
  }

  function filterMountingsByType(mounting) {
    // if (attributeMountingType) {
    //   return mounting.data.webMountingType === attributeMountingType;
    // }
    return true;
  }

  // function filterT2sByIPRating(T2) {
  //   if (attributeIPRating) {
  //     return parseInt(T2.data.webIPRating) >= attributeIPRating;
  //   }
  //   return true;
  // }

  function filterT1sByFinish(T1) {
    if (activeFilters.Finish) {
      return T1.data.webFinish === activeFilters.Finish;
    }
    return true;
  }

  // If there are T1 filters active and at least 1 value present
  if (
    activeFilters.T1 &&
    Object.entries(activeFilters.T1).length > 0 &&
    Object.values(activeFilters.T1).filter((item) => item).length > 0
  ) {
    // setShowT1s(true);
    Object.entries(activeFilters.T1).forEach(([key, value]) => {
      if (value === "") return;
      const loopT1s = [];
      switch (key) {
        case "Finish":
          loopT1s.push(...T1s.filter((T1) => T1.data.webFinish === value));
          break;
        case "Colour Temperature":
          loopT1s.push(
            ...T1s.filter((T1) => T1.data.webColourTemperature === value)
          );
          break;
        case "Control Protocol":
          loopT1s.push(
            ...T1s.filter(
              (T1) =>
                T1.data.webControlProtocolIntegrated === value ||
                T1.data.webControlProtocolRemote === value
            )
          );
          break;
        case "Luminous Flux":
          // ["0 - 999lm", "1000lm - 1999lm", "2000lm+"]
          switch (value) {
            default:
            case "0 - 999lm":
              loopT1s.push(
                ...T1s.filter(
                  (T1) =>
                    Array.isArray(T1.data.webFlux) &&
                    T1.data.webFlux.length > 0 &&
                    T1.data.webFlux[0] < 1000
                )
              );
              break;
            case "1000lm - 1999lm":
              loopT1s.push(
                ...T1s.filter(
                  (T1) =>
                    Array.isArray(T1.data.webFlux) &&
                    T1.data.webFlux.length > 0 &&
                    T1.data.webFlux[0] < 2000 &&
                    T1.data.webFlux[0] >= 1000
                )
              );
              break;
            case "2000lm+":
              loopT1s.push(
                ...T1s.filter(
                  (T1) =>
                    Array.isArray(T1.data.webFlux) &&
                    T1.data.webFlux.length > 0 &&
                    T1.data.webFlux[0] >= 2000
                )
              );
              break;
          }
          break;
        default:
          // clg("Invalid filter", key, value);
          break;
      }
      // Replace T1s with loopT1s
      // if (loopT1s.length > 0) T1s.splice(0, T1s.length, ...loopT1s);
      T1s.splice(0, T1s.length, ...loopT1s);
    });
    const T2sFromT1s = [];
    T1s.forEach((T1) => {
      T2sFromT1s.push(T1.data.T2[0]);
    });
    const T2sFromT1sUnique = T2sFromT1s.filter(onlyUnique);
    const T2sFromT1sRecords = [];
    if (T2sFromT1sUnique.length > 0) {
      T2sFromT1sUnique.forEach((recordId) => {
        const found = allProducts.T2s.find(
          (baseT2) => baseT2.recordId === recordId
        );
        if (found) {
          T2sFromT1sRecords.push(found);
        }
      });
    }
    // T2s.push(...T2sFromT1sRecords);
    T2s.splice(0, T2s.length, ...T2sFromT1sRecords);
  } else {
    // setShowT1s(false);
    // T1s.push(...allProducts.T1s);
    // T2s.push(...allProducts.T2s);
    T2s.splice(0, T2s.length, ...allProducts.T2s);
    T1s.splice(0, T1s.length, ...allProducts.T1s);
  }

  if (
    activeFilters.T2 &&
    Object.entries(activeFilters.T2).length > 0 &&
    Object.values(activeFilters.T2).filter((item) => item).length > 0
  ) {
    Object.entries(activeFilters.T2).forEach(([key, value]) => {
      const loopT2s = [];
      switch (key) {
        case "Power":
          loopT2s.push(
            ...T2s.filter(
              (T2) =>
                T2.data.webPower &&
                Array.isArray(T2.data.webPower) &&
                T2.data.webPower.includes(value.toString())
            )
          );
          break;
        case "IP Rating":
          switch (value) {
            case "44+":
              loopT2s.push(
                ...T2s.filter(
                  (T2) =>
                    T2.data.webIPRating && parseInt(T2.data.webIPRating) >= 44
                )
              );
              break;
            case "65+":
              loopT2s.push(
                ...T2s.filter(
                  (T2) =>
                    T2.data.webIPRating && parseInt(T2.data.webIPRating) >= 65
                )
              );
              break;
            default:
              break;
          }
          break;

        default:
          // clg("Invalid T2 filter", key, value);
          break;
      }
      T2s.splice(0, T2s.length, ...loopT2s);
    });
  }

  // Get Mountings from T2
  const mountingIds = [];
  T2s.forEach((T2) => {
    mountingIds.push(...T2.data.webMounting);
  });

  // Collate available mountings
  const availableMountings = [];
  mountingIds.filter(onlyUnique).forEach((id) => {
    const record = mountings.find(({ recordId }) => recordId === id);
    if (record) availableMountings.push(record);
  });

  // mountings.push(
  //   ...[...allProducts.mountings]
  //     .filter(filterMountingsBySurface)
  //     .filter(filterMountingsByType)
  // );
  // mountings.splice(
  //   0,
  //   mountings.length,
  //   ...[...allProducts.mountings]
  //     .filter(filterMountingsBySurface)
  //     .filter(filterMountingsByType)
  // );
  // mountings.push(
  //   ...[...allProducts.mountings]
  //     .filter(filterMountingsBySurface)
  //     .filter(filterMountingsByType)
  // );

  // activeFilters.forEach((activeFilter) => {
  //   // clg(activeFilter);
  // });

  // // Are we filtering T1s?
  // if (showT1s) {
  //   T1s.push(...base.T1s.filter(filterT1sByFinish));
  //   // Shortlist T2s
  //   const T2sFromT1s = [];
  //   T1s.forEach((T1) => {
  //     T2sFromT1s.push(T1.data.T2[0]);
  //   });
  //   const T2sFromT1sUnique = T2sFromT1s.filter(onlyUnique);
  //   const T2sFromT1sRecords = [];
  //   if (T2sFromT1sUnique.length > 0) {
  //     T2sFromT1sUnique.forEach((recordId) => {
  //       const found = base.T2s.find((baseT2) => baseT2.recordId === recordId);
  //       if (found) {
  //         T2sFromT1sRecords.push(found);
  //       }
  //     });
  //   }
  //   T2s.push(...T2sFromT1sRecords.filter(filterT2sByIPRating));
  // } else {
  //   T1s.push(...allProducts.T1s);
  //   T2s.push(...allProducts.T2s.filter(filterT2s));
  // }
  // // Get Mountings from T2
  // const populatedMountingRecordIds = [];
  // T2s.forEach((T2) => {
  //   populatedMountingRecordIds.push(...T2.data.webMounting);
  // });
  // mountings.push(
  //   ...[...base.attributes.mountings]
  //     .filter(filterMountingsBySurface)
  //     .filter(filterMountingsByType)
  // );
  // const filtered = { ...currentProducts };
  // clg({ mountings });
  // clg({ availableMountings });
  const filteredProducts = {
    T2s,
    T1s,
    mountings: availableMountings,
  };
  return filteredProducts;
};

export const getUniqueAttributeValuesT2 = (T2s, T1s) => {
  // We only really store T1 attributes at the moment
  const attributesT2 = {
    // powers: [],
    IPRatings: [],
    distributionTypes: [],
  };
  // const powers = [];
  const IPRatings = [];
  const distributionTypes = [];

  T1s.forEach((T1) => {
    // if (T2.data.webPower && Array.isArray(T2.data.webPower)) {
    //   T2.data.webPower.forEach((power) => {
    //     powers.push(parseInt(power));
    //   });
    // }
    if (T1.data.webIPRating) {
      IPRatings.push(parseInt(T1.data.webIPRating));
    }
    // if (T2.data.webDistributionType) {
    //   distributionTypes.push(...T2.data.webDistributionType);
    // }
  });

  // attributesT2.powers.push(...powers.filter(onlyUnique).sort(numericalSort));
  attributesT2.IPRatings.push(
    ...IPRatings.filter(onlyUnique).sort(numericalSort)
  );
  attributesT2.distributionTypes.push(
    ...distributionTypes.filter(onlyUnique).sort()
  );

  return attributesT2;
};

export const getUniqueAttributeValuesT1 = (T1s) => {
  const attributesT1 = {
    colourTemperatures: [],
    controlProtocols: [],
    distributions: [],
    finishes: [],
    fluxes: [],
    beamCategories: [],
    driverLocations: [],
  };

  const colourTemperatures = [];
  const controlProtocols = [];
  const distributions = [];
  const finishes = [];
  const fluxes = [];
  const beamCategories = [];

  T1s.forEach((T1) => {
    if (T1.data.webColourTemperature) {
      colourTemperatures.push(T1.data.webColourTemperature);
    }
    if (T1.data.webControlProtocolIntegrated) {
      controlProtocols.push(T1.data.webControlProtocolIntegrated);
    }
    if (T1.data.webControlProtocolRemote) {
      controlProtocols.push(T1.data.webControlProtocolRemote);
    }
    if (T1.data.webDistribution) {
      distributions.push(T1.data.webDistribution);
    }
    if (T1.data.webFinish) {
      finishes.push(T1.data.webFinish);
    }
    if (T1.data.webFlux) {
      fluxes.push(...T1.data.webFlux);
    }
    if (T1.data.webBeamCategory) {
      beamCategories.push(...T1.data.webBeamCategory);
    }
  });

  if (T1s.find((T1) => T1.data.webControlProtocolIntegrated !== null)) {
    attributesT1.driverLocations.push("Integrated");
  }

  if (T1s.find((T1) => T1.data.webControlProtocolRemote !== null)) {
    attributesT1.driverLocations.push("Remote");
  }

  attributesT1.colourTemperatures.push(
    ...colourTemperatures.filter(onlyUnique).sort()
  );

  attributesT1.controlProtocols.push(
    ...sortControlProtocols([...controlProtocols.filter(onlyUnique).sort()])
  );

  attributesT1.distributions.push(...distributions.filter(onlyUnique).sort());

  attributesT1.finishes.push(
    ...sortFinishes([...finishes.filter(onlyUnique).sort()])
  );

  attributesT1.fluxes.push(...fluxes.filter(onlyUnique).sort(numericalSort));

  attributesT1.beamCategories.push(...beamCategories.filter(onlyUnique).sort());

  return attributesT1;
};

export const getAvailableAttributes = (products, setAvailableAttributes) => {
  setAvailableAttributes({
    mountings: [
      ...products.mountings.sort((a, b) =>
        a.webDisplayOrder > b.webDisplayOrder ? 1 : -1
      ),
    ],
    T2: getUniqueAttributeValuesT2(products.T2s),
    T1: getUniqueAttributeValuesT1(products.T1s),
  });
  // return {
  //   mountings: [
  //     ...products.mountings.sort((a, b) =>
  //       a.webDisplayOrder > b.webDisplayOrder ? 1 : -1
  //     ),
  //   ],
  //   T2: getUniqueAttributeValuesT2(products.T2s),
  //   T1: getUniqueAttributeValuesT1(products.T1s),
  // };
};
