/* eslint-disable react-hooks/rules-of-hooks */

/*
Product Category
  Control Protocol
  Beam Category
  Flux (range)
  Colour Temperature
  IP Rating
  Driver Location
*/

import { graphql } from "gatsby";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Helmet } from "react-helmet";
import FilterMounting from "../components/FilterMounting";
import ProductFilterForm from "../components/ProductFilterForm";
import {
  getAvailableAttributes,
  filterProducts,
  filterProductsSimply,
  getUniqueAttributeValuesT1,
  getUniqueAttributeValuesT2,
} from "../utils/filterHelpers";

function getAllProducts(data) {
  return {
    T2s: data.T2s.nodes,
    T1s: data.T1s.nodes,
    mountings: [
      ...data.mountings.nodes.sort((a, b) =>
        a.data.webDisplayOrder > b.data.webDisplayOrder ? 1 : -1
      ),
    ],
  };
}

function getAllAttributes(data) {
  const attributes = {
    mountings: [
      ...data.mountings.nodes.sort((a, b) =>
        a.data.webDisplayOrder > b.data.webDisplayOrder ? 1 : -1
      ),
    ],
    productAttributes: {
      ...getUniqueAttributeValuesT1(data.T1s.nodes),
      ...getUniqueAttributeValuesT2(data.T2s.nodes, data.T1s.nodes),
    },
  };
  return attributes;
}

function getAttributes(data) {
  const attributes = {
    mountings: [
      ...data.mountings.sort((a, b) =>
        a.data.webDisplayOrder > b.data.webDisplayOrder ? 1 : -1
      ),
    ],
    productAttributes: {
      ...getUniqueAttributeValuesT1(data.T1s),
      ...getUniqueAttributeValuesT2(data.T2s, data.T1s),
    },
  };
  return attributes;
}

export default function filter({ data }) {
  /* 02 Get all Mounting, T2, T1 attributes */
  /* 03 Initialise state for "everything that changes" */
  /* 04 Declare what happens when state changes */
  /* 05 UI binding */
  /* 06 Return JSX */

  const productImages = data.productImages.nodes;

  /* 01 Get all Mounting, T2, T1 */
  const allProducts = useMemo(() => getAllProducts(data), [data]);
  const allAttributes = useMemo(() => getAllAttributes(data), [data]);

  /* Initialise State */
  const isMounted = useRef(false);
  const [showT1s, setShowT1s] = useState(false);
  const [activeFilters, setactiveFilters] = useState({
    T1: {},
    T2: {},
    mounting: {},
  });
  const [availableAttributes, setAvailableAttributes] = useState({
    ...allAttributes,
  });
  const [currentProducts, setCurrentProducts] = useState({ ...allProducts });

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    const filteredProducts = filterProductsSimply({
      products: allProducts,
      filters: activeFilters,
    });
    // const filteredProducts = filterProducts({
    //   allProducts,
    //   activeFilters,
    // });
    setCurrentProducts(filteredProducts);
    // clg(activeFilters);
  }, [activeFilters, allProducts]);

  useEffect(() => {
    // clg(currentProducts.mountings);
    if (currentProducts.T1s?.length < 200) {
      if (
        Array.isArray(currentProducts.mountings) &&
        currentProducts.mountings.length === 1 &&
        currentProducts.mountings[0].data.Name === "Profile Recessed"
      ) {
        setShowT1s(false);
      } else {
        setShowT1s(true);
      }
    } else {
      setShowT1s(false);
    }

    setAvailableAttributes(getAttributes(currentProducts));
    // clg({ currentProducts });
    // clg({ allAttributes });
    // clg({ availableAttributes });
  }, [currentProducts, showT1s]);
  // }, [allAttributes, currentProducts, availableAttributes]);

  useEffect(() => {
    // clg({ allAttributes });
    // clg({ availableAttributes });
  }, [allAttributes, availableAttributes, currentProducts]);

  // /* Buttons */

  const handleTempButtonClick = (e) => {
    e.preventDefault();
    setShowT1s(!showT1s);
  };

  let attributeMountingSurface = "";
  attributeMountingSurface = "";

  let attributeMountingType = "";
  attributeMountingType = "";

  let attributeIPRating = "";
  attributeIPRating = "";

  let attributeFinish = "";
  attributeFinish = "";

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Product Filter" key="ogtitle" />
      </Helmet>
      <main id="main">
        <div className="grid-content-footer pt-small px-medium">
          <div className="grid-child-content">
            {/* <aside className="pb-large">
              <ul>
                <li>
                  <button
                    className="button-capsule-link button-select small"
                    type="button"
                    onClick={handleTempButtonClick}
                  >
                    Toggle Show T1
                  </button>
                </li>
                <li>
                  Mountings: {currentProducts.mountings.length}/
                  {allAttributes.mountings.length}
                </li>
                <li>
                  T2s: {currentProducts.T2s.length}/{allProducts.T2s.length}
                </li>
                <li>
                  T1s: {currentProducts.T1s.length}/{allProducts.T1s.length}
                </li>
                <li>IP Rating: {attributeIPRating || "None"}</li>
                <li>Finish: {attributeFinish || "None"}</li>
              </ul>
            </aside> */}
            <ProductFilterForm
              allAttributes={allAttributes}
              availableAttributes={availableAttributes}
              activeFilters={activeFilters}
              setactiveFilters={setactiveFilters}
            />
            <ul className="">
              {currentProducts.mountings.map((mounting, i) => {
                if (
                  mounting.data.Name &&
                  Array.isArray(mounting.data.T2) &&
                  mounting.data.T2.length > 0
                ) {
                  return (
                    <FilterMounting
                      showT1s={showT1s}
                      mounting={mounting.data}
                      T2s={currentProducts.T2s.filter(
                        (T2) =>
                          T2.data.webMounting &&
                          T2.data.webMounting[0] === mounting.recordId
                      )}
                      T1s={currentProducts.T1s.filter((T1) => {
                        if (T1.data.webMountings === null) {
                          return false;
                        }
                        if (T1.data.webMountings[0] === mounting.recordId) {
                          return true;
                        }
                        return false;
                      })}
                      key={`mounting-${i}`}
                      productImages={productImages}
                    />
                  );
                }
                return <></>;
              })}
            </ul>
          </div>
          <div className="grid-child-footer">
            <div className="fs-medium" />
          </div>
        </div>
      </main>
    </>
  );
}

export const query = graphql`
  query {
    mountings: allAirtableMountings(
      filter: { data: { webPublishedHomepage: { eq: true } } }
    ) {
      nodes {
        data {
          Name
          webDisplayOrder
          webMountingSurface
          webMountingType
          T2 {
            recordId
          }
        }
        recordId
      }
    }
    T2s: allAirtableProductsT2(
      filter: { data: { webPublishedResults: { eq: true } } }
    ) {
      nodes {
        data {
          webDisplayNameFirst
          webDisplayNameSecondShort
          # webDistribution
          # webDistributionType
          webFluxRange
          webHeroImage {
            data {
              Filename
            }
          }
          # webIPRating
          webMounting
          # webPower
          # webPublishedResults
          webSlug
          webT4Slug
        }
        recordId
      }
    }
    T1s: allAirtableProductsT1(
      filter: { data: { webPublished: { eq: true } } }
    ) {
      nodes {
        data {
          T2
          webBeamCategory
          webColourTemperature
          webControlProtocolIntegrated
          webControlProtocolRemote
          webDistribution
          webFinish
          webFlux
          webIPRating
          webModelCode
          webMountings
          webPower
          webSlug
          webT4Slug
        }
        recordId
      }
    }
    productImages: allImageSharp {
      nodes {
        gatsbyImageData(
          formats: JPG
          jpgOptions: { quality: 75 }
          sizes: "(min-width: 2000px) calc(25vw - 60px), (min-width: 1400px) calc(33.28vw - 63px), (min-width: 1100px) calc(50vw - 72px), (min-width: 600px) calc(50vw - 36px), calc(100vw - 32px)"
          placeholder: BLURRED
          width: 1200
        )
        parent {
          ... on File {
            relativePath
          }
        }
      }
    }
  }
`;
