import React from "react";
import { sortFinishes } from "../utils/helpers";
import FormSelectButtonGeneric from "./FormSelectButtonGeneric";
import FormSelectGeneric from "./FormSelectGeneric";

export default function ProductFilterForm({
  allAttributes,
  availableAttributes,
  activeFilters,
  setactiveFilters,
}) {
  const handleSelectChange = (e) => {
    const currentactiveFilters = { ...activeFilters };
    const { label } = e.target.dataset;
    let value = "";
    if (e.target.dataset.selected === "false") {
      value = e.target.value;
    }
    const { tier } = e.target.dataset;
    currentactiveFilters[tier][label] = value;
    setactiveFilters(currentactiveFilters);
  };

  const { productAttributes } = allAttributes;

  return (
    <>
      <section className="configurator-panel-container filter-panel-container-temp mb-large">
        {/* <section className="configurator-panel-container mb-large"> */}
        {/* <h1 className="fs-large fs-ml-xl fs-mxxxl-xxl">Product Filter</h1>
        <hr className="mt-medium mb-large my-ml-small" /> */}
        <FormSelectButtonGeneric
          label="Category"
          tier="mounting"
          allAttributes={[...allAttributes.mountings]}
          availableAttributes={
            availableAttributes?.mountings && [...availableAttributes.mountings]
          }
          value={activeFilters.mounting.Category}
          handleChange={handleSelectChange}
        />
        <hr />
        {productAttributes.IPRatings.length > 0 && (
          <FormSelectButtonGeneric
            label="IP Rating"
            tier="T2"
            allAttributes={["44+", "65+"]}
            availableAttributes={
              availableAttributes.productAttributes.IPRatings
            }
            value={activeFilters.T2["IP Rating"]}
            handleChange={handleSelectChange}
          />
        )}
        <hr />
        {/* {productAttributes.powers.length > 0 && (
          <FormSelectGeneric
            label="Power"
            tier="T2"
            allAttributes={productAttributes.powers}
            value={activeFilters.power}
            handleChange={handleSelectChange}
          />
        )} */}
        {productAttributes.colourTemperatures.length > 0 && (
          <FormSelectButtonGeneric
            label="Colour Temperature"
            tier="T1"
            allAttributes={productAttributes.colourTemperatures}
            availableAttributes={
              availableAttributes.productAttributes.colourTemperatures
            }
            value={activeFilters.T1["Colour Temperature"]}
            handleChange={handleSelectChange}
          />
        )}
        <hr />
        {productAttributes.controlProtocols.length > 0 && (
          <FormSelectButtonGeneric
            label="Control Protocol"
            tier="T1"
            allAttributes={productAttributes.controlProtocols}
            availableAttributes={
              availableAttributes.productAttributes.controlProtocols
            }
            value={activeFilters.T1["Control Protocol"]}
            handleChange={handleSelectChange}
          />
        )}
        <hr />
        {productAttributes.finishes.length > 0 && (
          <FormSelectButtonGeneric
            label="Finish"
            tier="T1"
            allAttributes={productAttributes.finishes}
            availableAttributes={availableAttributes.productAttributes.finishes}
            value={activeFilters.T1.Finish}
            handleChange={handleSelectChange}
          />
        )}
        <hr />
        {/* {productAttributes.fluxes.length > 0 && (
          <FormSelectButtonGeneric
            label="Luminous Flux"
            tier="T1"
            allAttributes={["0 - 999lm", "1000lm - 1999lm", "2000lm+"]}
            availableAttributes={availableAttributes.productAttributes.fluxes}
            value={activeFilters.T1[]}
            handleChange={handleSelectChange}
          />
        )} */}
        {productAttributes.beamCategories.length > 0 && (
          <FormSelectButtonGeneric
            label="Beam Angle"
            tier="T1"
            allAttributes={productAttributes.beamCategories}
            availableAttributes={
              availableAttributes.productAttributes.beamCategories
            }
            value={activeFilters.T1["Beam Angle"]}
            handleChange={handleSelectChange}
          />
        )}
        <hr />
        {productAttributes.driverLocations.length > 0 && (
          <FormSelectButtonGeneric
            label="Driver Location"
            tier="T1"
            allAttributes={productAttributes.driverLocations}
            availableAttributes={
              availableAttributes.productAttributes.driverLocations
            }
            value={activeFilters.T1["Driver Location"]}
            handleChange={handleSelectChange}
          />
        )}
        <hr />
      </section>
    </>
  );
}
