import React from "react";
import { naturalSortNameSplit } from "../utils/helpers";
import FilterT2 from "./FilterT2";

export default function FilterMounting({
  showT1s,
  mounting,
  T2s,
  T1s,
  productImages,
}) {
  // Filter out all unpublished T2s
  // const publishedT2s = mounting.T2.filter(
  //   (obj) => obj.data.webPublishedResults === true
  // );
  // const T2s = naturalSortNameSplit(publishedT2s);
  // if (T2s.length > 0) {

  // Do not render if there are no T2s
  if (T2s?.length <= 0) return <></>;

  const sortedT2s = naturalSortNameSplit(T2s);

  return (
    <li>
      <div className="fs-xxl my-large">{mounting.Name}</div>
      {showT1s ? (
        <ul className="pb-large thumbnail-list grid-fixed-1 mb-small">
          {sortedT2s.map((T2, i) => (
            <FilterT2
              showT1s={showT1s}
              T2={T2}
              T1s={T1s}
              key={`T2-${i}`}
              productImages={productImages}
            />
          ))}
        </ul>
      ) : (
        <ul className="pb-large thumbnail-list grid-fixed-2 grid-fixed-ms-3 grid-fixed-mm-3 grid-fixed-mxl-4 grid-fixed-mxxxl-5 mb-small">
          {sortedT2s.map((T2, i) => (
            <FilterT2
              showT1s={showT1s}
              T2={T2}
              T1s={T1s}
              key={`T2-${i}`}
              productImages={productImages}
            />
          ))}
        </ul>
      )}
    </li>
  );
  // }
  // return null;
}
