import React from "react";
import { naturalSort } from "../utils/helpers";
import ProductImage from "./ProductImage";

export default function FilterT2({ showT1s, T2, T1s, productImages }) {
  const filteredT1s = T1s.filter((T1) => T1.data.T2[0] === T2.recordId);

  if (!showT1s) {
    return (
      <li>
        <a
          href={`/range/${T2.data.webT4Slug}/${T2.data.webSlug}/`}
          className="thumbnail-container"
          target="_blank"
          rel="noreferrer"
        >
          {T2.data.webHeroImage && T2.data.webHeroImage.length > 0 ? (
            <div className="thumbnail-image">
              <ProductImage
                filename={T2.data.webHeroImage[0].data.Filename}
                alt={`${T2.data.webDisplayNameFirst} ${
                  T2.data.webDisplayNameSecondShort
                    ? T2.data.webDisplayNameSecondShort
                    : ""
                }`}
                productImages={productImages}
              />
            </div>
          ) : (
            <div className="thumbnail-image">
              <ProductImage placeholder />
            </div>
          )}
          <div className="thumbnail-name px-small pt-small c-em">
            <span className="fw-medium">{T2.data.webDisplayNameFirst}</span>
            {T2.data.webDisplayNameSecondShort && (
              <>
                <br />
                <span>{T2.data.webDisplayNameSecondShort}</span>
              </>
            )}
          </div>
        </a>
      </li>
    );
  }
  return (
    <li className="results-verbose-temp">
      <div className="filter-T2-T1-wrapper">
        <header className="mb-small">
          <a
            className="name"
            href={`/range/${T2.data.webT4Slug}/${T2.data.webSlug}/`}
            target="_blank"
            rel="noreferrer"
          >
            <span className="fw-medium">{T2.data.webDisplayNameFirst}</span>
            {T2.data.webDisplayNameSecondShort && (
              <>
                <br />
                <span>{T2.data.webDisplayNameSecondShort}</span>
              </>
            )}
          </a>
          <a
            className="thumbnail"
            href={`/range/${T2.data.webT4Slug}/${T2.data.webSlug}/`}
            target="_blank"
            rel="noreferrer"
          >
            <ProductImage
              filename={T2.data.webHeroImage[0].data.Filename}
              alt={`${T2.data.webDisplayNameFirst} ${
                T2.data.webDisplayNameSecondShort
                  ? T2.data.webDisplayNameSecondShort
                  : ""
              }`}
              productImages={productImages}
            />
          </a>
        </header>

        <div className="table-overflow-wrapper">
          <table className="generic ">
            <thead>
              <tr>
                <th className="fw-medium">Model Code</th>
                <th className="fw-medium">Finish</th>
                <th className="fw-medium">Colour</th>
                <th className="fw-medium">Control Protocol</th>
                <th className="fw-medium">Flux</th>
                <th className="fw-medium">Beam</th>
              </tr>
            </thead>
            <tbody>
              {naturalSort(filteredT1s, "webModelCode").map((T1, i) => (
                <tr key={`T1-${i}`}>
                  <td className="fw-medium">
                    <a
                      href={`/range/${T1.data.webT4Slug}/${T1.data.webSlug}/`}
                      className=""
                      target="_blank"
                      rel="noreferrer"
                    >
                      {T1.data.webModelCode}
                    </a>
                  </td>
                  <td>{T1.data.webFinish}</td>
                  <td>{T1.data.webColourTemperature}</td>
                  <td>
                    {T1.data.webControlProtocolIntegrated && (
                      <>{T1.data.webControlProtocolIntegrated} (Integrated)</>
                    )}
                    {T1.data.webControlProtocolRemote && (
                      <>{T1.data.webControlProtocolRemote} (Remote)</>
                    )}
                  </td>
                  <td>{T1.data.webFlux}lm</td>
                  <td>{T1.data.webBeamCategory}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </li>
  );
}
